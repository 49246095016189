<template>
  <div class="purchase-rules">
    <Head :headInfo="headInfo" @headBack="goBack"></Head>
    <div class="rule-list">
      <template v-for="(item, index) in ruleList">
        <div :class="[item.arrowClick ? 'active' : 'inactive', 'rule-inner']" :key="item.code">
          <div>
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <van-icon v-if="item.arrowClick" name="arrow-up" @click="handleSpread(index)"/>
            <van-icon v-else name="arrow-down" @click="handleSpread(index)"/>
          </div>
          <div class="content">
            <template v-for="item_c in item.content">
              <div><p>{{ item_c }}</p><br></div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Icon } from 'vant'
import Head from '../../components/fullWebviewHead.vue'

Vue.use(Icon)
export default {
  name: 'purchaseRules',
  data () {
    return {
      //1元抢规则
      ruleList: [],
      headInfo: {}
    }
  },
  components: {
    Head
  },
  created () {
    this.headInfo.title = '1元抢规则'
    this.ruleList = [
      {
        code: 1,
        title: '中奖规则',
        arrowClick: false,
        content: ['1.抽奖码和开奖码一致（数字值和顺序均一致），则即为中奖；举例，开奖码为596873，抽奖为596873，则视为中奖；', '2.中奖用户可直接获得奖品，1元将不予以退还；中奖商品联系客服，平台将根据用户您的具体中奖信息，实物商品发快递，虚拟商品直接线上充值；', '3.平台保障实物商品100%为实物商品，非优惠券或满减形式等非实物商品发放。如遇收到货后为空包或非实物商品请及时与客服联系！', '4.如未中奖，用户可以选择继续参与1元购或者参与平台的1元红包膨胀活动，也可以选择申请退款，申请退款后退款金额将在3个工作日内原路返还。']
      },
      {
        code: 2,
        title: '开奖码产生规则',
        arrowClick: false,
        content: ['开奖码由7位数字按顺序组成，开奖码通过7个城市(北京、上海、深圳、广州、杭州、成都、郑州)早上8点的“高德地图拥堵延时指数”的第二位小数按顺序拼成7位中奖号码；如果第二位小数为0则取0作为号码；“高德地图拥堵延时指数”由高的地图提供的第三方数据，可点击高德地图官网进行各城市拥堵延时指数查询；', '高德地图官网：https://report.amap.com/detail.do?city=110000']
      },
      {
        code: 3,
        title: '如何领奖',
        arrowClick: false,
        content: ['1.开奖后，中奖用户需主动领取奖品，中奖之日起若超过5日未领取，则将视为自动放弃该奖品；', '2.领取操作：找到参与记录中的领奖入口，点击入口将跳转至客服页面，请将手机号、中奖截图发给客服进行兑奖，实物商品发快递，虚拟商品直接线上充值；', '3.领取中奖权益仅限当前中奖商品使用，且有效期为未中奖之日起3日内，请务必在有效期限内使用。因过期未使用、收货地址填写错误等非平台原因而造成的奖品损失，需由用户自行承担。']
      },
      {
        code: 4,
        title: '平台警示',
        arrowClick: false,
        content: ['1.如用户以不正当手段或舞弊方式参与本活动，一经发现，鲸选师有权对用户的账户进行异常标记，回收所有奖励。不正当手段包括但不限于使用非法工具等，举例：通过工具刷量多个抽奖码;', '2.商品价值仅供参考，因商品价格存在市场变动，均不影响用户参与抽奖与最终奖励领取，鲸选师官方客服不会在沟通过程中收取额外的费用！请谨防受骗！', '2.活动期间发生任何不可抗力或情势变更的情况（包括但不限于重大灾害事件、活动受政府机关指令需停止举办或调整的、活动遭受严重网络攻击或因系统故障需要暂停举办的），趣多省有权暂停或取消本次活动，并可依相关法律法规的规定主张免责;', '3.本活动由鲸选师App发起，与苹果官方无关。如需进一步了解活动规则，请咨询鲸选师客服（工作日8:00-24:00）;', '4.本活动仅限18周岁以上中国大陆公民参加。']
      }
    ]
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    //  在鲸选师APP内，设置APP的状态栏
    if (this.$store.state.isAndroidApp) {
      this.$h5AppLink('updateStyle', {
        statusBarBgColor: 'white',
        statusBarTextColor: 'black'
      })
    }
  },
  methods: {
    /*
    展开规则
     */
    handleSpread (index) {
      this.ruleList[index].arrowClick = !this.ruleList[index].arrowClick
    },

    /*
    返回
     */
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-rules {
  width: 100%;
  min-height: 100vh;
  background: #F4F4F4;


  .rule-list {
    width: 100%;
    margin-bottom: 35px;
    display: inline-block;
    text-align: center;

    .rule-inner {
      display: inline-block;
      margin: 12px auto 0;
      width: 350px;
      overflow: hidden;
      background: #FFFFFF;
      border-radius: 10px;

      & > div:first-child {
        margin: 16px 32px 12px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;

        .title {
          position: relative;
          width: 94px;
          height: 26px;
          background: linear-gradient(90deg, rgba(255, 122, 130, 0.6) 0%, rgba(255, 51, 51, 0) 100%);
          border-radius: 4px;

          p {
            width: 140px;
            position: absolute;
            left: 8px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 26px;
          }

        }

        .van-icon {
          font-size: 16px;
        }
      }

      .content {
        margin: 0 20px;
        text-align: left;

        p {
          font-size: 15px;
          color: #333333;
          line-height: 21px;
          word-break: break-all;
        }
      }
    }

    .inactive {
      height: 130px;

      .content {
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    .active {
      min-height: 130px !important;
    }
  }

}
</style>
